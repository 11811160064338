/* Import common definition styles */
@import '../../../styles/common.scss';


/* ---------------------------------------------------------
 *  APPLICATION GLOBAL - Generic Styles
 * ---------------------------------------------------------
**/
html {
    @include appFont-default();
    font-size: $app_base-fontSize;
}

body {
    background-color: transparent;
    color: $main_color;
    overflow: hidden;
    margin: 0;

    font-family: inherit;
    font-size: inherit;
    @include no-select();
}

a {
    cursor: pointer;
    text-decoration: underline;
    color: $main_anchor-color;

    &:visited {
        color: $main_anchor-color;
    }

    &:active, &:focus, &:hover {
        text-decoration: underline;
        color: $main_anchor-hover-color;
    }
}

h1, h2, h3, h4, h5, h6, span, label {
    @include appFont-default();
    color: inherit;
}

input {
    font-family: inherit !important;
}

img {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
}

// Error Boundary Component
div.error-boundary-wrapper {
    background-color: $errorBoundary_bgColor;
    color: $errorBoundary_color;
    text-align: left;
    padding: 1em;

    & > blockquote {
        display: inline-block;
        font: 14px/22px normal helvetica, sans-serif;
        margin-top: 10px;
        margin-bottom: 10px;
        margin-left: 20px;
        padding-left: 15px;
        padding-right: 10px;
        border-left: $errorBoundary_blockquote-borderLeft;
        background-color: $errorBoundary_blockquote-bgColor;
        color: $errorBoundary_color;
    }
    
    & > blockquote p::before {
        content: "\201C";
    }
    
    & > blockquote p::after {
        content: "\201D";
    }

    & > h1 {
        font-size: 2rem;
    }

    & > h2 {
        font-size: 1.25rem;
    }

    .error-action {
        margin-top: 1em;
    }
}

// Root App element
#root {
    font-family: inherit;
    font-size: inherit;
    color: inherit;
    height: 100%;
    background-size: cover;
    text-align: center;

    & > .app-cached-image-wrapper {
        display: block;
        width: 250px;
        position: fixed;
        top: 0;
        left: -2500px;
        margin-left: -2500px;
        visibility: hidden;
        img {
            height: 10px;
            width: auto;
        }
    }

    & > .app-viewport {
        min-width: $app_minWidth;
        height: 100vh; /* Fallback for browsers that do not support Custom Properties */
        height: calc(var(--vh, 1vh) * 100);

        display: flex;
        flex-flow: column;   
        flex-direction: column;

        &:not(.external-service) {
            background-color: $main_bgColor;
        }

        &.external-service {
            display: block;
            height: 100%;
            overflow-y: auto;
            overflow-x: hidden;
            @include scrollbar-style();

            &.mobile {
                @include scrollbar-hide();
            }

        }

        &:not(.mobile) {
            .content {
                @include scrollbar-style();
            }

            .accordion > .accordion-item > .title {
                cursor: pointer;
            }
        }

        &.mobile {
            .content {
                @include scrollbar-hide();
            }

            .accordion > .accordion-item > .title {
                cursor: auto; /* avoid mobile selecting/blink effect with cursor pointer */
            }
        }

        & > .app-viewport-row {
            &.header {
                flex: 0 1 auto;  /* same as: flex-grow: 0; flex-shrink: 1; flex-basis: auto */                
            }

            &.content {
                flex: 1 1 auto;  /* same as: flex-grow: 1; flex-shrink: 1; flex-basis: auto */                

                &:not(.hide-overflow) {
                    overflow-y: auto;
                    overflow-x: hidden;
                }

                &.hide-overflow {
                    overflow: hidden;
                }
            }
        }
    }

    .app-form-button {
        @include mc-form-button();
    }

    .app-compact-button {
        @include mc-compact-button();
    }

    .app-confirmation-button {
        @include mc-confirmation-button();
    }

    .app-message-area {
        &.error {
            @include message-box($main_warning-color) {
                margin-top: $appContent_defaultVerticalPadding;
            }
        }

        &.information {
            @include message-box($main_information-bgColor) {
                margin-top: $appContent_defaultVerticalPadding;
            }
        }
    }

    .bold-text {
        @include appFont-bold();
    }

    .strikethrough {
        text-decoration: line-through;
    }

    .btn-visualizar, .btn-baixar-pdf, .btn-compartilhar, .btn-revogar {
        background-color: transparent;
        border: none;
        padding: 0;
        border-radius: 0;
        width: auto;
        height: auto;
        
        &:focus {
            outline: none;
        }

        &:hover {
            cursor: pointer;
        }

        &.disabled {
            img {
                opacity: 0.5;
            }
        }

        img {
            width: auto;
            height: $downloadViewButton_height;
        }
    }
    
    .padding-rodape {
        margin-top: 4vh;
    }

    .svg-invert {
        filter: invert(100%) sepia(0%) saturate(7496%) hue-rotate(266deg) brightness(99%) contrast(107%);
    }
}

/*
** Semantic-ui overrides */
.ui.form {
    font-size: inherit;
}

.ui.image {
    vertical-align: middle;
}

// Accordion
@mixin accordionContentTransition() {
    -webkit-transition: max-height 0.3s cubic-bezier(0, 1, 0, 1);
       -moz-transition: max-height 0.3s cubic-bezier(0, 1, 0, 1);
        -ms-transition: max-height 0.3s cubic-bezier(0, 1, 0, 1);
         -o-transition: max-height 0.3s cubic-bezier(0, 1, 0, 1);
            transition: max-height 0.3s cubic-bezier(0, 1, 0, 1);
}

.accordion {
    background-color: transparent !important;
    color: $accordion_default-text-color;
    padding-left: 18px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-family: inherit;
    font-size: 1em;

    &.ui.fluid {
        padding-left: 0;
        box-shadow: none;
        font-family: inherit;

        .accordion-item {
            padding: 0;
            color: inherit;
            cursor: auto;
        
            &, & .title {
                border-top-right-radius: 0;
                border-top-left-radius: 0;
                font-family: inherit;
            }

            &:not(:first-child) {
                margin-top: 0.75rem;
            }

            & .title {
                background-color: $accordion_default-title-bgColor;
                color: inherit;

                padding: 0.75rem 1em;
                display: flex;
                border: none;
                justify-content: flex-start;
                line-height: 1.5em;
                font-weight: normal;

                & .title-content {
                    padding-right: 0.75em;
                    font-family: inherit;
                }

                & .collapsible-wrapper {
                    margin: auto 0 auto auto;

                    & span::after {
                        content: '\02C7';//'\002B';
                        color: $accordion_default-toggle-color;
                        font-weight: bold;
                        float: right;
                        padding-top: 0;
                    }
                }
            }
            
            &.emphasis {
                & .title {
                    background-color: $accordion_emphasis-title-bgColor;
                    color: $accordion_emphasis-title-color;
                }
            }

            &.active .title .collapsible-wrapper span::after {
                content: "\2212";
            }

            & .content {
                background-color: $accordion_default-content-bgColor;
                color: inherit;
                display: block;   
                padding: 0;
                overflow: hidden;    
                max-height: 0;
                font-family: inherit;

                @include accordionContentTransition();

                & .content-row {
                    padding: 0;

                    &:last-child {
                        & .linha-divisao {
                            display: none;
                        }
                    }

                    &:not(.disabled) {
                        &.novo {
                            color: $main_emphasis-color;
                        }
                    }
                }
            }

            &.active > .content {
                max-height: 1000000px; /* If use 100% the transiction will not work */
            }
        }
    }
}

// General styles
.clear { 
    clear: both; 
    font-size: 0; 
    line-height: 0;
}

.allow-select {
    @include allow-select();
}

.no-select {
    @include no-select();
}

.not-visible {
    visibility: hidden;
}

.no-wrap {
    white-space: nowrap;
}

.no-resize {
    resize: none !important;
}

.scrollbar-measure-box {
    position: absolute;
    top: -9999px;
    left: 0px;
    width: 100px;
    height: 100px;
    overflow: hidden;
    visibility: hidden;

    @include scrollbar-style();
}

.hidden {
    display: none !important;
}

.paragraph-wrapper {
    margin-bottom: 1em !important;
}

.flex-break {
    flex-basis: 100%;
    height: 0;
}

/* colors */
.mc-success {
    color: $main_success-color;
}

.mc-information {
    color: $main_information-bgColor;
}

.mc-warning {
    color: $main_warning-color;
}

.mc-bg-warning {
    background-color: $main_warning-color;
}

/* margin */
.mt-1em {
    margin-top: 1em;
}

.mb-1em {
    margin-bottom: 1em;
}

.ml-1em {
    margin-left: 1em;
}

.mr-1em {
    margin-right: 1em;
}

/* Hide Google reCaptcha */
.grecaptcha-badge { 
    visibility: hidden !important;
}


/* ---------------------------------------------------------
 *  APPLICATION GLOBAL - Responsiveness
 * ---------------------------------------------------------
**/
